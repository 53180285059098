// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-case-studies-js": () => import("./../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-company-js": () => import("./../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-confirmation-js": () => import("./../src/pages/newsletter-confirmation.js" /* webpackChunkName: "component---src-pages-newsletter-confirmation-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-refer-lister-js": () => import("./../src/pages/refer/lister.js" /* webpackChunkName: "component---src-pages-refer-lister-js" */),
  "component---src-pages-refer-policy-js": () => import("./../src/pages/refer/policy.js" /* webpackChunkName: "component---src-pages-refer-policy-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-software-feature-js": () => import("./../src/pages/software/feature.js" /* webpackChunkName: "component---src-pages-software-feature-js" */),
  "component---src-pages-software-js": () => import("./../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-tools-js": () => import("./../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-tools-tool-js": () => import("./../src/pages/tools/tool.js" /* webpackChunkName: "component---src-pages-tools-tool-js" */),
  "component---src-templates-resource-template-js": () => import("./../src/templates/ResourceTemplate.js" /* webpackChunkName: "component---src-templates-resource-template-js" */)
}

